<template>
  <div>
    <md-card class="md-layout-item md-size-30 md-small-size-100">
      <md-card-header>
        <div class="md-title">{{ $t('label.personal_props') }}</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <span :style="{'font-weight': 500}">{{ $t("label.full_name") }}: </span>
            <span>{{ me.full_name }}</span>
          </div>
        </div>
        <p></p>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-button class="md-success" @click="onShowPasswordChange">
              <i :class="`fas fa-key`"></i> {{ $t('label.change_password') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog :md-active.sync='showPasswordChange'>
      <md-dialog-content>
        <md-field>
          <label>{{ $t('user.password') }}</label>
          <md-input v-model='password.orig' type='password'></md-input>
        </md-field>
        <md-field>
          <label>{{ $t('user.password_confirm') }}</label>
          <md-input v-model='password.confirm' type='password'></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button :disabled='!passwordApply' class='md-simple' @click='onSetPassword'>{{ $t('button.apply') }}</md-button>
        <md-button class='md-simple' @click='showPasswordChange = false'>{{ $t('button.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Swal from "sweetalert2";

export default {
  name: 'meProfileForm',
  data() {
    return {
      showPasswordChange: false,
      password: {orig: '', confirm: ''},
      passwordApply: true,
    }
  },
  computed: {
    ...mapState({
      me: state => state.Login.me
    })
  },
  methods: {
    onShowPasswordChange() {
      this.password.orig=''
      this.password.confirm=''
      this.passwordApply = true
      this.showPasswordChange = true
    },
    async onSetPassword() {
      const alert = {
        type: 'success',
        text: this.$t(`user.password_was_set`),
        footer: ''
      }
      if (this.password.orig !== this.password.confirm) {
        alert.type = 'warning'
        alert.text = this.$t('user.passwords_do_not_match')
        await Swal.fire(alert)
        return
      }
      try {
        await this.$store.dispatch('SET_MY_PASSWORD', this.password.orig)

        this.passwordApply = true
        await Swal.fire(alert)

      } catch (err) {
        console.log(err)
        alert.type = 'error'
        alert.text = this.$t(`user.password_was_not_set`)
        alert.footer = this.$t(err.message ? err.message : err)
        await Swal.fire(alert)
        //throw err
      }
    }
  }
}
</script>
<style lang="scss">

</style>